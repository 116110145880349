<template>
  <div>
    <div class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <div class="main-info main-info--no-visual ">
          <h1 class="main-info-title">Privacy
            <span class="main-info-title__highlighted">notice</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="simple-text privacy-notice">
        <h2>Hi there! We are the folks behind this website.</h2>
        <p>As part of our commitment to your privacy we created this privacy notice which will inform you as to our data gathering, dissemination and data protection policies in accordance with the EU General Data Protection Regulation.</p>
        <h3>Compliance</h3>
        <p>By using our site, you are agreeing to the content of our privacy policy as defined below. If you do not fully agree with the terms of our policy, please do not make use of our site.</p>
        <p>Users under 13 should obtain parental or guardian consent to use the website and share any data through the website forms.</p>
        <h3>Data Collection</h3>
        <p>We use the information that we collect to provide you with improved services. We collect data through three main routes:</p>
        <ul>
          <li>Information that you share with us voluntarily such as your name and email address when leaving a comment or requesting a quote in special form;</li>
          <li>Data collected by our website. This includes and is not limited to: ISP, Browser used, IP address, pages accessed, date and time, number of clicks, exit pages, entry pages, etc.</li>
          <li>Location information: Your IP address allows us to see where you are located with regards to country, state, and location.</li>
        </ul>
        <h3>Information Use</h3>
        <p>Data gathered by us in accordance with this policy is used for the purpose of improving your experience within this site and to help us make better business decisions. We use your usage and location information to tailor your experience with this website to your needs. While non-personal information may be used for website diagnostics and improvements.</p>
        <p>Data is collected from users wherever you may access our site around the world and may be processed on a server located outside of your own country.</p>
        <p>Data collected is neither shared, sold nor rented to any third party for commercial reasons.</p>
        <h3>Security of Information</h3>
        <p>All data collected by us is treated as being confidential and is held on secure servers/storages. Access to the data collected is regulated and restricted by reasons.</p>
        <h3>Your Rights</h3>
        <p>All users of our website have the following rights:</p>
        <ul>
          <li>the right to be informed about the information collected;</li>
          <li>the right to access shared information at any time;</li>
          <li>the right to rectify and update information shared with our representatives;</li>
          <li>the right to request full deletion of shared information from our databases;</li>
          <li>the right to restrict processing of shared data;</li>
          <li>the right to request transfer of the data to another party upon confirmed request;</li>
          <li>the right to object the way data is processed and stored;</li>
          <li>the right not to be a subject to automated decision-making processes.</li>
        </ul>
        <p>To make a request regarding your rights please contact our us at privacy@iternal.com</p>
        <h3>Links</h3>
        <p>ITernal is not responsible for the content and policies of other sites that may be reached through the use of links to third party sites through this site. Users follow these links at their own risk and shall check those websites privacy statements.</p>
        <h3>Policy Changes</h3>
        <p>ITernal reserves the right to make changes to this policy at any time. We will post the changes to this page and encourage you to review them to stay informed. If we make changes that materially alter your privacy rights, we will provide additional notice, such as via email or through the Website.</p>
        <h3>Contacts:</h3>
        <div class="privacy-notice-text">
          <span><b>RADEMADE TECHNOLOGIES OÜ</b></span>
          <span>Company number: 14253682</span>
          <span>Address: Harju maakond, Tallinn, Kesklinna linnaosa, Tuukri tn 19-202</span>
          <span>Estonia 10120</span>
          <span><a href="mailto:privacy@iternal.group">privacy@iternal.group</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Privacy-notice',
    metaInfo: {
      title: 'Privacy notice'
    },
    components: {
    }
  }
</script>

<style lang="scss">
  .privacy-notice {
    padding-top: 60px;
    padding-bottom: 40px;
    width: 100%;
    max-width: none;

    h3 {
      padding-top: 20px;
    }

    h2 {
      padding-top: 20px;
      margin-bottom: 15px;
      color: #292929;
      font-size: 2.375rem;
      line-height: 1.42;
      font-weight: 600;

      @media (max-width: $viewport-tablet) {
        padding-top: 10px;
        margin-bottom: 10px;
        font-size: 20px;
      }
    }

    p {
      font-size: 1.125rem;
      margin-bottom: 20px;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    li {
      margin-bottom: 10px;
      font-size: 1.125rem;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      margin-bottom: 25px;
      border: 1px solid #999;

      th {
        padding: 10px;
        font-size: 1.125rem;
        text-align: left;
        border: 1px solid #999;
        font-weight: 600;

        @media (max-width: $viewport-tablet) {
          font-size: 1rem;
        }

        &:first-child {
          width: 30%;
        }
      }

      td {
        padding: 10px;
        font-size: 1.125rem;
        line-height: 28px;
        border: 1px solid #999;

        @media (max-width: $viewport-tablet) {
          font-size: 1rem;
        }
      }
    }

    .privacy-notice-text {
      font-size: 1.125rem;
      margin-bottom: 20px;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    .privacy-notice-text span {
      display: block;
    }
  }
</style>
