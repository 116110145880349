<template>
  <div>
    <div class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <div class="main-info main-info--no-visual ">
          <h1 class="main-info-title">Cookie 
            <span class="main-info-title__highlighted">policy</span>
          </h1>
          <p class="main-info-description">
            In ITernal we do believe we can be transparent about how we collect and use your data. This policy provides information about how and when we use cookies when you use our website.
          </p>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="simple-text cookie-policy">
        <h3>What is a cookie?</h3>
        <p>Cookies are small text files we send to your computer or mobile device. They are unique to your account or your browser. Session-based cookies last only while your browser is open and are automatically deleted when you close your browser. Persistent cookies last until you or your browser delete them or until they expire.</p>
        <p>You can read more about cookies at &nbsp;<a href="http://www.allaboutcookies.org/" target="_blank">http://www.allaboutcookies.org/.</a></p>
        <h3>Do we use cookies?</h3>
        <p>Yes. We use cookies and similar technologies like single-pixel gifs and web beacons. We use both session-based and persistent cookies to provide you services or to make it easier to navigate the website. We set and accesses our own cookies on the website and we also use third party cookies, like Google Analytics.</p>
        <h3>How do we use cookies?</h3>
        <p>Some cookies are associated with your account and personal information in order to remember that you are logged in and which services you order. Other cookies are not tied to your account but are unique and allow us to carry out analytics and customization, among other similar things.</p>
        <p>Cookies can be used to recognize you when you visit our website or use our services, remember your preferences, and give you a personalized experience that’s consistent with your settings. Cookies also make your interactions faster and more secure.</p>
        <h3>How we use cookies for advertising?</h3>
        <p>Cookies and other ad technology such as beacons, pixels, and tags help us market more effectively to users that we and our partners believe may be interested in our services. They also help provide us with aggregated auditing, research, statistics and reporting, and know when content has been shown to you.</p>
        <h3>What to do if you don't want cookies to be set?</h3>
        <p>You may prefer not to allow cookies, which is why most browsers give you the ability to manage cookies to suit your interest. In some browsers you may also manage cookies preferences for each particular website.</p>
        <p>You can find the instructions how to manage your cookies for different browsers below:</p>
        <ul>
          <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a></li>
          <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Mozilla Firefox</a></li>
          <li><a href="https://support.apple.com/kb/ph21411?locale=en_US" target="_blank">Safari</a></li>
          <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&amp;hl=en" target="_blank">Android Browser</a></li>
          <li><a href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank">Opera</a></li>
        </ul>
        <p>For other browsers, please consult the documentation that your browser manufacturer provides.</p>
        <p>You may opt-out of third party cookies from Google Analytics on its website.</p>
        <p>You can opt out of interest-based targeting provided by participating ad servers through the Digital Advertising Alliance (http://youradchoices.com). In addition, on your iPhone, iPad or Android, you can change your device settings to control whether you see online interest-based ads.</p>
        <p><b>Note!</b> If you limit the ability of websites and applications to set cookies, you may worsen your overall user experience and/or lose the ability to access the services, since it will no longer be personalized to you. It may also stop you from saving customized settings, like login information.</p>
        <h3>Cookies used on our website</h3>
        <table>
          <tr>
            <th>Categories of Use</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>Authentication</td>
            <td>If you're signed in to our Website, cookies help us show you the right information and personalize your experience.</td>
          </tr>
          <tr>
            <td>Security</td>
            <td>We use cookies to enable and support our security features, and to help us detect malicious activity.</td>
          </tr>
          <tr>
            <td>Preferences features and services</td>
            <td>Cookies can tell us which language you prefer and what your communications preferences are. They can help you fill out forms on our Website more easily. They also provide you with features, insights, and customized content.</td>
          </tr>
          <tr>
            <td>Marketing</td>
            <td>We may use cookies to help us deliver marketing campaigns and track their performance (e.g., a user visited our support page and then made a purchase). Similarly, our partners may use cookies to provide us with information about your interactions with their services but use of those third-party cookies would be subject to the service provider’s policies.</td>
          </tr>
          <tr>
            <td>Performance, Analytics and Research</td>
            <td>Cookies help us learn how convenient our Website is for users. We also use cookies to understand, improve, and research products, features, and services, including to create logs and record when you access our Website from different devices, such as your work computer or your mobile device.</td>
          </tr>
        </table>
        <h3>Contact Us</h3>
        <p>If you have any questions about this Cookie Policy, please contact us at: &nbsp;<a href="mailto:privacy@iternal.group">privacy@iternal.group</a></p>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "Cookies",
    metaInfo: {
      title: 'Cookies'
    },
    components: {
    }
  }
</script>

<style lang="scss">
  .cookie-policy {
    padding-top: 60px;
    padding-bottom: 40px;
    width: 100%;
    max-width: none;

    h3 {
      padding-top: 20px;
    }

    p {
      font-size: 1.125rem;
      margin-bottom: 20px;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    li {
      margin-bottom: 10px;
      font-size: 1.125rem;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      margin-bottom: 25px;
      border: 1px solid #999;

      th {
        padding: 10px;
        font-size: 1.125rem;
        text-align: left;
        border: 1px solid #999;
        font-weight: 600;

        @media (max-width: $viewport-tablet) {
          font-size: 1rem;
        }

        &:first-child {
          width: 30%;
        }
      }
      
      td {
        padding: 10px;
        font-size: 1.125rem;
        line-height: 28px;
        border: 1px solid #999;
        
        @media (max-width: $viewport-tablet) {
          font-size: 1rem;
        }
      }
    }
  }
</style>
