<template>
  <div>
    <section class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <v-main-info :data="mainInfoData" class="main-info--no-visual"></v-main-info>
        <v-client-types-block :data="clientTypes"></v-client-types-block>
        <v-expertise-preview :data="expertisePreview"></v-expertise-preview>
      </div>
    </section>
    <section class="section-box section-box--highlighted" id="reviews">
      <div class="wrapper">
        <v-reviews-block></v-reviews-block>
      </div>
    </section>
    <!-- <section class="section-box section-box--no-bot-padding" id="clients">
      <v-clients-map></v-clients-map>
    </section> -->
  </div>
</template>

<script>
import MainInfo from '@/components/layout/Main-info/Main-info'
import ClientTypeBlock from '@/components/layout/Client-types/Сlient-types-block'
import ExpertisePreview from '@/components/layout/Expertise/Expertise-preview'
import ReviewsBlock from '@/components/layout/Reviews/Reviews-block'
//import ClientsMap from '@/components/layout/Clients-map/Clients-map'

export default {
  name: "Key-clients",
  metaInfo: {
    title: 'Outsourcing for SMB, Growing Startups and Enterprise – Key Clients ITernal Group'
  },
  data() {
    return {
      mainInfoData: {
        title: '<span class="main-info-title__row">We help companies to <span class="main-info-title__highlighted">move forward</span></span><span class="main-info-title__row">faster</span>',
        subTitle: 'We are focused on eliminating all the pains that accompany any business through a lifespan. That’s why we dive deep to find and remove any blockers, to help our clients make an impact on markets and reach their business goals.',
      },
      clientTypes: {
        title: '',
        list: [
          {
            title: 'Technology expertise for IT companies',
            icon: 'smb',
            text : '<p>Prevent bottlenecks and set the wheels of the prolonged development process in motion. To meet deadlines, we have a great talents and can handle the whole project by our team.</p>' +
                '<ul>' +
                '<li>Move and scale faster</li>' +
                '<li>Prioritize tasks and feature sets</li>' +
                '<li>Find the right tools</li>' +
                '<li>Straighten the development flow</li>' +
                '</ul>'
          },
          {
            title: 'Software development from scratch',
            icon: 'planet',
            text : '<p>Get a ready-to-sell software in time. With ITernal, you get access to multi-industry expertise that helps to avoid the main mistakes when launching a new business.</p>' +
                '<ul>' +
                '<li>Launch a new business without stress</li>' +
                '<li>Get an assembled team of skilled software engineers</li>' +
                '<li>Stick with proven technology stacks</li>' +
                '<li>Build a software and improve it with every iteration</li>' +
                '</ul>'
          },
          {
            title: 'R&D for non-residents',
            icon: 'startup',
            text : '<p>Go test drive before opening your own R&D center in Ukraine. Benefit from expert advice on legal and recruitment to avoid unnecessary risks or set up a company on a turn-key basis.</p>' +
                '<ul>' +
                '<li>Get access to the largest tech talents pool in Ukraine</li>' +
                '<li>Minimize risks and financial loss</li>' +
                '<li>Low and simple tax rates</li>' +
                '<li>Launch R&D in 2 month</li>' +
                '</ul>'
          }
        ]
      },
      expertisePreview: {
        title: 'Disrupt and develop markets',
        list: [
          {
            title: 'HealthCare',
            icon: 'expertise-healthcare',
            description: 'We develop high-performance software solutions helping healthcare companies to drive industry transformations and utilize the latest digital technologies.',
            casesAmount: '3'
          },
          {
            title: 'Marketplaces',
            icon: 'expertise-trading',
            description: 'Hosted, serviced solution, scalable architecture, low latency, cloud-enabled features. Custom development of proprietary platforms, infrastructures, systems. Reduced Cost.',
            casesAmount: '3'
          },
          {
            title: 'FinTech',
            icon: 'expertise-fintech',
            description: 'Experienced Fintech Developers that can help you with your Digital Transformation. Successful track record with People, Process, technology and Culture. Fintech. Near-zero Defect Software.',
            casesAmount: '3'
          }
        ]
      }
    }
  },
  components: {
    'v-main-info': MainInfo,
    'v-client-types-block': ClientTypeBlock,
    'v-expertise-preview': ExpertisePreview,
    'v-reviews-block': ReviewsBlock,
    //'v-clients-map': ClientsMap,
  }
}
</script>

<style lang="scss">

</style>
