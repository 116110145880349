<template>
  <div>
    <h3 class="section-subbox-title section-subbox-title--no-controls" v-if="data.title">{{ data.title }}</h3>
    <div class="client-types-container" v-if="data.list && data.list.length">
      <div class="client-type" v-for="item in data.list" :key="item.title">
        <span class="client-type__title">
          {{ item.title }}
          <component :is="'v-icon-' + item.icon" class="client-type__img" :class="'client-type__img--' + item.icon"></component>
        </span>
        <span class="client-type-text" v-html="item.text"></span>
      </div>
    </div>
  </div>
</template>

<script>
import IconSmb from '@/assets/icons/smb.svg'
import IconPlanet from '@/assets/icons/planet.svg'
import IconStartup from '@/assets/icons/startup.svg'

export default {
  props: {
    data: Object
  },
  components: {
    'v-icon-smb': IconSmb,
    'v-icon-planet': IconPlanet,
    'v-icon-startup': IconStartup
  }
}
</script>

<style lang="scss">
.client-types-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 110px;
}

.client-type {
  width: 50%;
  padding: 65px 90px 55px;
  color: #fff;
  background-color: #161616;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  @media (max-width: $viewport-tablet) {
    padding: 45px 70px 50px;
  }

  @media (max-width: $viewport-tablet-small) {
    padding: 35px 60px 40px 40px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    background-color: #202020;
    padding-left: 30px;
    margin-bottom: 1px;
  }

  &:hover {
    background-color: #202020;

    .client-type__link {
      opacity: 1;
    }
  }
}

.client-type__title {
  position: relative;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;

  @media (max-width: $viewport-mobile) {
    font-size: 1.75rem;
    line-height: 1.3;
  }
}

.client-type__img {
  position: absolute;

  &--smb {
    bottom: 9px;
    right: -50px;
    width: 34px;
    height: 31px;

    @media (max-width: $viewport-mobile) {
      bottom: 6px;
    }
  }

  &--startup {
    bottom: 8px;
    right: -50px;
    width: 34px;
    height: 34px;

    @media (max-width: $viewport-mobile) {
      bottom: 6px;
    }
  }

  &--planet {
    bottom: 7px;
    right: -45px;
    width: 28px;
    height: 28px;

    @media (max-width: $viewport-mobile) {
      bottom: 5px;
    }
  }
}

.client-type-text {
  display: block;
  margin-top: 30px;
  font-size: 0.875rem;
  line-height: 2;

  @media (max-width: $viewport-mobile) {
    line-height: 1.75;
  }

  p {
    margin-bottom: 15px;
  }

  li {
    display: block;
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    font-size: 0.875rem;
    line-height: 2;

    @media (max-width: $viewport-mobile) {
      line-height: 1.75;
    }

    &:after {
      content: '';
      position: absolute;
      top: 13px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: #fff;
    }
  }
}

.client-type__link {
  display: inline-block;
  position: relative;
  margin-top: 50px;
  font-weight: bold;
  font-size: 0.75rem;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-tablet) {
    margin-top: 30px;
  }

  @media (max-width: $viewport-mobile) {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -60px;
    width: 13px;
    height: 13px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.016 4.926a.73.73 0 00-.228-.527L7.618.229C7.451.069 7.28 0 7.102 0c-.406 0-.698.286-.698.673 0 .203.082.374.21.501l1.427 1.448 1.841 1.682-1.472-.09H.717c-.425 0-.717.293-.717.712 0 .412.292.704.717.704h7.694l1.472-.088-1.84 1.682L6.613 8.67a.699.699 0 00-.21.501c0 .388.293.673.699.673.178 0 .35-.07.501-.216l4.184-4.183a.728.728 0 00.228-.52z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    filter: invert(100%);
    transform: translateY(-50%);
  }
}
</style>
