<template>
  <div>
    <div class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <div class="main-info main-info--no-visual ">
          <h1 class="main-info-title">
            Term of use
          </h1>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="simple-text term-of-use">
        <p><b>Rademade Technologies OÜ</b> (herein referred to as “ITernal” or “we”, “us”, “our”), a legal entity under the laws of Estonia, company no. 14253682, with its registered address at: Harju maakond, Tallinn, Kesklinna linnaosa, Tuukri tn 19-202, Estonia 10120, maintains this Website as its landing page for business and services presentation. Access to and use of this Website is subject to the terms and conditions (the “Terms”) set forth herein and all applicable laws. We may revise and modify the existing Terms, introduce changes to these Terms or add additional ones to any element of these Terms from time to time by updating this statement at any time without giving you notice. You may browse and use this Website for your own personal, non-commercial use with the purpose of information access and communication with us.</p>
        <p>If you do not accept these terms, please do not use and do not access this Website.</p>
        <h3>Copyright and Trademarks</h3>
        <h5>Copyright Notice</h5>
        <p>All contents of the Website are protected by copyright and are owned by us or licensed to us by our suppliers, contractors, affiliates and/or partners. All rights reserved. You agree to comply with all copyright notices, trademark rules, information, and restrictions contained herein, and, in any content, you can find on the Website.</p>
        <p>While we maintain copyright protection and/or exercises licensing rights in all publications and information it places on the Website, we consent to normal downloading, copying and distribution of the information for non-commercial purposes within the user’s organization only. In consideration of this, users agree that copies of the information will retain all copyright and other proprietary notices. No intellectual property right the Website (or any part thereof) is intended to, and it shall not be deemed to, transfer to any person who accesses the Website.</p>
        <h3>Legal Terms</h3>
        <p>This Website may contain other proprietary notices and copyright information, the terms of which must be observed and followed. Information on this Website may contain typographical errors or technical inaccuracies. Information may be changed or updated without notice. We may also make improvements and/or changes in the products and/or the services and/or the programs described in this information at any time without notice.</p>
        <p>Please do not share any confidential or proprietary information through our Website.</p>
        <h5>Termination and Suspension of Use</h5>
        <p>You may access the Website solely for your own personal, non-commercial use with the purpose of information access and communication with us.</p>
        <p>You must not use any part of the Website for any illegal purpose. In particular, you may not use the Website for any of the following purposes:</p>
        <ul>
          <li>disseminating any unlawful, harassing, defamatory, abusive, threatening, harmful, obscene, or otherwise objectionable material or otherwise breaching any laws;</li>
          <li>transmitting material that encourages conduct that constitutes a criminal offense, or otherwise breaches any applicable laws, regulations or code(s) of practice;</li>
          <li>interfering with any other person’s use of the Website; or</li>
          <li>making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner.</li>
        </ul>
        <p>If you breach any of the provisions of these Terms, you will be responsible for any losses and costs resulting from your breach.</p>
        <p>We reserve the right to terminate your use of the Website if you breach any of these Terms.</p>
        <p>If we reasonably suspect your use of the Website is affecting the lawful operation of the Website or may adversely affect other persons, we may suspend your access to the Website at any time while we investigate the position.</p>
        <p>If necessary, we may at any time suspend access to the Website or close it indefinitely. We will not be liable if for any reason the Website is unavailable at any time or for any period.</p>
        <h3>Limitation of Liability</h3>
        <p>Our obligations with respect to the products and/or services described or mentioned in the information placed on this Website are governed solely by the agreements under which such information and/or the products and/or services are provided. If you obtain any information, a product or service from us via this Website that is provided without an agreement, that information, product or service is provided “as is” with no warranties whatsoever, express or implied, and your use of that information, product or service is at your own risk. In addition, a link to a third-party website does not mean that we endorse or accept any responsibility for the content, or the use, of such websites. It is up to you to take precautions to ensure that whatever you select for your use is free of such items as viruses, worms, trojan horses and other items of a destructive nature.</p>
        <p>In no event will we be liable to you or any other person, whether arising out of or in connection with information this Website contains, and/or a products and/or service described in this Website including but not limited to</p>
        <ul>
          <li>phone, electronic, hardware or software, network, Internet, email, or computer malfunctions, failures or difficulties of any kind;</li>
          <li>failed, incomplete, garbled or delayed computer and/or email transmissions;</li>
          <li>any condition caused by events beyond the control of the company that might cause the services to be (as applicable) delayed, disrupted, or corrupted;</li>
          <li>any injuries, losses or damages of any kind arising in connection with, or as a result of, utilizing the services.</li>
        </ul>
        <p>In no event will we be liable to you or any other person, whether arising out of or in connection with your use of the Website, or your inability to use the Website, or for any other reason including, without limitation, for:</p>
        <ul>
          <li>any matters due to any events outside our reasonable control; or</li>
          <li>any unforeseeable losses or damages.</li>
        </ul>
        <p>Warning: to avoid any doubts please be aware that in no event will we be liable to you or other any party for any direct, indirect, special or other consequential damages for any use of this website, or on any other hyper linked Website, including, without limitation, any lost profits, business interruption, loss of programs or other data on your information handling system or otherwise, even if we are expressly advised of the possibility of such damages.</p>
        <h3>Contact Us</h3>
        <p>If you have any questions about this Cookie Policy, please contact us at: &nbsp;<a href="mailto:privacy@iternal.group">privacy@iternal.group</a></p>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'Term-of-use',
    metaInfo: {
      title: 'Term of use'
    },
    components: {
    }
  }
</script>

<style lang="scss">
  .term-of-use {
    padding-top: 60px;
    padding-bottom: 40px;
    width: 100%;
    max-width: none;

    h3 {
      padding-top: 20px;
    }

    p {
      font-size: 1.125rem;
      margin-bottom: 20px;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    li {
      margin-bottom: 10px;
      font-size: 1.125rem;

      @media (max-width: $viewport-tablet) {
        font-size: 1rem;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      margin-bottom: 25px;
      border: 1px solid #999;

      th {
        padding: 10px;
        font-size: 1.125rem;
        text-align: left;
        border: 1px solid #999;
        font-weight: 600;

        @media (max-width: $viewport-tablet) {
          font-size: 1rem;
        }

        &:first-child {
          width: 30%;
        }
      }

      td {
        padding: 10px;
        font-size: 1.125rem;
        line-height: 28px;
        border: 1px solid #999;

        @media (max-width: $viewport-tablet) {
          font-size: 1rem;
        }
      }
    }
  }
</style>
